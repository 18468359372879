/***************************************************************************************************
 * COMPONENTS AND THEMES
 */

/***************************************************************************************************
  * DECLARATIONS
  */
@use 'node_modules/ag-grid-community/styles' as ag;

/*
 * Angular Material, material design components
 * see https://material.angular.io

 */

@import 'libs/shared/ui/styles/src/lib/scss/material-theme';

/*
  * ag grid components
  * see https://www.ag-grid.com/angular-grid
  */
@include ag.grid-styles(
  (
    theme: material,
    material-primary-color: $primary,
    material-accent-color: $info,
    checkbox-checked-color: $primary,
  )
);

/***************************************************************************************************
  * UTILITIES
  */

/*
  * TailwindCSS, utility-first css framework
  * see https://tailwindcss.com/docs
  */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul {
    list-style: revert;
    margin: revert;
    padding: revert;
  }
}

.table-learnmore {
  tr {
    @apply even:bg-white odd:bg-hover-overlay min-w-full w-full;

    td,
    th {
      @apply px-1 py-2;
    }
    th {
      @apply py-4;
    }
  }
}
.learn-more-contents.hardnessConverter {
  img.learnmore-image {
    @apply w-full max-w-3xl;
  }
  h1,
  h2,
  h3,
  h4 {
    @apply font-bold;
  }
  h1 {
    @apply text-subtitle-1 text-h6;
  }
  h2 {
    @apply text-subtitle-2;
  }
}

.table-learnmore.table-compact {
  @apply max-w-3xl;
}

/***************************************************************************************************
  * OVERRIDES
  */

.ag-theme-material {
  --ag-font-family: 'Noto sans';
  --ag-selected-row-background-color: #eee;
  .ag-column-panel {
    border-left: 1px solid ag-param(border-color);

    border-right: 1px solid ag-param(border-color);
  }

  .ag-paging-panel {
    @apply text-caption;
  }

  .ag-standard-button.ag-filter-apply-panel-button {
    @apply px-2;
  }
}

mac-base-dialog .mat-mdc-button .mdc-button__label,
mac-base-dialog .mat-mdc-unelevated-button .mdc-button__label,
mac-base-dialog .mat-mdc-raised-button .mdc-button__label,
mac-base-dialog .mat-mdc-outlined-button .mdc-button__label {
  z-index: unset;
}

.mat-calendar-body-cell:focus .mat-focus-indicator::before {
  margin: 0;
}
